// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-blog-js": () => import("./../../src/pages/Blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../src/pages/ContactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-mission-js": () => import("./../../src/pages/OurMission.js" /* webpackChunkName: "component---src-pages-our-mission-js" */),
  "component---src-pages-thank-you-js": () => import("./../../src/pages/ThankYou.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-page-template-js": () => import("./../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

